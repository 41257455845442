<template>
  <div class="zone">
    <TJDetailTitle :titleData="'备件统计'" />
    <functionBox  :titleData="{permission_flag:'SparePart',nowID:1}" @searchBtn="searchBtn" :searhLoading="loading">
      <template>
        <li>
          <el-select
            v-model="platform_id"
            placeholder="请选择平台"
            size="small"
            filterable
            clearable
          >
            <el-option
              v-for="item in optionsPlatform"
              :key="item.platform"
              :label="item.platform_unique"
              :value="item.platform"
            ></el-option>
          </el-select>
        </li>
        <li>
          <el-input
            placeholder="请输入机号"
            v-model="instrument_id"
            clearable
            size="small"
          ></el-input>
        </li>
        <li>
          <el-select
            v-model="article_id"
            placeholder="请选择备件"
            size="small"
            filterable
            clearable
            remote
            :remote-method="remoteMethod_article"
          >
            <el-option
              v-for="item in optionsPart"
              :key="item.article_id"
              :label="item.note"
              :value="item.article_id"
            ></el-option>
          </el-select>
        </li>
        <li>
          <el-date-picker
          size="small"
          v-model="dateValue"
          format="yyyy/MM/dd"
          value-format="yyyyMMdd"
          type="daterange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          style="marginLeft:10px"
          class="Calendar"
        />
        </li>
       
        <li>
          <el-select
            v-model="province_id"
            placeholder="请选择省份"
            size="small"
            @change="changeProvince"
            filterable
            clearable
          >
            <el-option
              v-for="item in optionsProvince"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </li>

        <li>
          <el-select
            v-model="organization_id"
            placeholder="请输入机构"
            size="small"
            filterable
            clearable
            remote
            :remote-method="remoteMethod"
          >
            <el-option
              v-for="item in organizationList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </li>
        <li>
          <el-input
            placeholder="请输入工程师"
            v-model="engineer_name"
            clearable
            size="small"
          ></el-input>
        </li>
      </template>
    </functionBox>
    <!-- <div class="indent">
 
      <div class="below">
        <div class="city">
          <el-select
            v-model="platform_id"
            placeholder="请选择平台"
            size="small"
            filterable
            clearable
          >
            <el-option
              v-for="item in optionsPlatform"
              :key="item.platform"
              :label="item.platform_unique"
              :value="item.platform"
            ></el-option>
          </el-select>
        </div>
        <div class="city1">
          <el-input
            placeholder="请输入机号"
            v-model="instrument_id"
            clearable
            size="small"
          ></el-input>
        </div>
        <div class="city2">
          <el-select
            v-model="article_id"
            placeholder="请选择备件"
            size="small"
            filterable
            clearable
            remote
            :remote-method="remoteMethod_article"
          >
            <el-option
              v-for="item in optionsPart"
              :key="item.article_id"
              :label="item.note"
              :value="item.article_id"
            ></el-option>
          </el-select>
        </div>
        <el-date-picker
          size="small"
          v-model="dateValue"
          format="yyyy/MM/dd"
          value-format="yyyyMMdd"
          type="daterange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          style="marginLeft:10px"
          class="Calendar"
        />
      </div>
      <div class="below">
        <div class="city">
          <el-select
            v-model="province_id"
            placeholder="请选择省份"
            size="small"
            @change="changeProvince"
            filterable
            clearable
          >
            <el-option
              v-for="item in optionsProvince"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>

        <div class="city2">
          <el-select
            v-model="organization_id"
            placeholder="请输入机构"
            size="small"
            filterable
            clearable
            remote
            :remote-method="remoteMethod"
          >
            <el-option
              v-for="item in organizationList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="city2">
          <el-input
            placeholder="请输入工程师"
            v-model="engineer_name"
            clearable
            size="small"
          ></el-input>
        </div>


        <div class="seachone">
          <el-button type="primary" size="small" @click="searchBtn">
            搜索
          </el-button>
        </div>
      </div>
    </div> -->
    <div class="sheet">
      <el-table :data="tableData" stripe height="600" v-loading="loading">
        <el-table-column
          type="index"
          label="序号"
          width="100"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="engineer_name"
          label="工程师名称"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="article_note"
          label="备件名称"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="change"
          label="数量变化"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="batch"
          label="批号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="stats_day"
          label="创建时间"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="instrument_id"
          label="机号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="organization_name"
          label="机构"
          align="center"
        ></el-table-column>
      </el-table>

      <div class="el_pagination_style">
        <el-pagination
          :hide-on-single-page="total <= 0"
          background
          layout="total,prev, pager, next,jumper"
          :total="total"
          :current-page.sync="page"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/tongji'
import TJDetailTitle from '@/components/TJDetailTitle'
import timePicker from '@/components/timePicker'
import { hospitals_list,organization_list } from '@/api/search.js'
import FunctionBox from './components/functionBox.vue'
export default {
  components: { TJDetailTitle, timePicker,FunctionBox },
  name: 'Part',
  data() {
    return {
      optionsPlatform: [], // 平台下拉列表
      platform_id: '', //平台下拉列表-值

      instrument_id: '', //仪器下拉列表-值

      organizationList: [],//机构下拉框
      organization_id: '', // 机构ID

      optionsPart: [], //备件下拉框
      article_id: '', // 备件ID

      optionsProvince: JSON.parse(localStorage.getItem('province')), //省份下拉框
      province_id: '', //省份ID

      optionsCity: [], //城市下拉框
      city_id: '', //城市下拉框-值

      engineer_name: '', //工程师

      dateValue: '',
      radio: '年', //时间节点
      start_stats_year: '', //开始年份
      end_stats_year: '', //结束年份
      dateMonthValue: '', //月份和日期选择
      page: 1,
      size: 10,
      total: 0,

      loading: false, //加载动画
      tableData: [],
    }
  },
  
 
  mounted() {
    this.platformFun()
    // this.articleFun()
    this.article_reserve_listFun()

  },
  methods: {
    // 根据回调修改当前页的相关属性
    setTime(val) {
      this[val.name] = val.value
    },
    //平台下拉框
    platformFun() {
      api.platform({}).then((res) => {
        this.optionsPlatform = res.data.data
      })
    },
    
    // 机构远程搜索
    remoteMethod(e) {
      this.organizationList = organization_list({ organization_name: e })
    },

    // 获取备件
    remoteMethod_article(e) {
      api.article({article_note:e}).then((res) => {
        this.optionsPart = res.data.data
      })
    },
    // //获取所有备件
    // articleFun() {
    //   api.article({size:'all'}).then((res) => {
    //     this.optionsPart = res.data.data
    //   })
    // },

    // 获取备件统计信息
    article_reserve_listFun() {
      this.loading = true

      let param = {
        page: this.page,
        size: this.size,
        organization_id: this.organization_id,//机构ID
        province_id: this.province_id, //省份ID
        engineer_name: this.engineer_name, //工程师
        platform_id: this.platform_id, //平台标识
        instrument_id: this.instrument_id, //仪器-机号
        article_id: this.article_id, //物品标识
      }
      if(this.dateValue){
        param.begin_time = this.dateValue[0]
        param.end_time = this.dateValue[1]
      }
      api.article_reserve_list(param).then(res=> {
        if (res.code == 200) {
          let data = res.data.data
          this.tableData = data
          this.total = res.data.count
        } else {
        }
      }).finally(msg => {
        this.loading = false
      })
    },

    // 监听省份下拉框选值
    changeProvince(value) {
      this.optionsCity = []
      this.city_id = ''
      let arr = this.optionsProvince.filter((e) => value == e.value)
      this.optionsCity = arr[0].city_list
    },

    
    // 年份联动选择处理,开始年份>结束年份
    changeYearData() {
      if (
        this.end_stats_year != '' &&
        this.start_stats_year > this.end_stats_year
      ) {
        this.end_stats_year = '' //结束年份
      }
    },
    
    // 搜索
    searchBtn() {
      this.page = 1
      this.article_reserve_listFun();
    },

   
    // 翻页
    handleCurrentChange(val) {
      this.page = val
      // this.stats_article_batch_listFun()
      this.article_reserve_listFun();
    },

    //   备件
    goSpare() {
      this.$router.push({ path: '/GoSpare' })
    },
    goBatch() {
      this.$router.push({ path: '/GoBatch' })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-date-editor .el-range__icon {
  display: none;
}

.indent {
  // width: px(1630);
  height: px(100);
  background: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(51, 64, 79, 0.1);
  border-radius: 5px;
  margin-bottom: px(20);
  padding: px(15);
}
.indentone {
  width: 100%;
  height: px(56);
  display: flex;
  border-bottom: 1px solid #d0d7e0;
  margin-bottom: px(15);
}
.left {
  justify-content: flex-start;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #111111;
  margin-right: px(20);
}
.spital {
  justify-content: flex-start;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #666666ff;
  margin-right: px(20);
}

.line {
  width: 1px;
  height: 13px;
  background-color: rgba(208, 215, 224, 1);
  margin-top: px(16);
  margin-right: px(20);
}

.right {
  flex: 1;
  justify-content: flex-end;
  display: flex;
  align-items: center;
}
.below {
  display: flex;
  margin-bottom: px(10);
}
.calendar {
  margin-left: px(10);
}
.cityProvince {
  margin-left: px(10);
}
.city1 {
  margin-left: px(10);
}
.city2 {
  margin-left: px(10);
}
::v-deep .cityProvince .el-input--small .el-input__inner {
  width: px(220);
}
.calendarone {
  display: flex;
  margin-left: px(10);
}
.seachone {
  margin-left: px(10);
}
.sheet {
  border-radius: px(10);
  background-color: #fff;
  margin-bottom: px(23);
}
.series {
  display: flex;
}
.series img {
  width: px(36);
  height: px(36);
}
.bd8 {
  width: 1px;
  height: 8px;
  border-radius: 0.5px 0.5px 0.5px 0.5px;
  background-color: rgba(208, 215, 224, 1);
  margin-top: 10px;
}
.combination {
  font-size: px(16);
  color: #333333;
  margin-top: px(10);
}
::v-deep .el-table__footer-wrapper .cell {
  font-size: px(14);
  font-weight: bold;
  color: #006ed0ff;
}
</style>
